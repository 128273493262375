/* Include Tailwind */
/* green hover #88d317 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

@import "tailwindcss/base";

@import "tailwindcss/utilities";
@import "tailwindcss/components";

@layer base {
  /*+ Body basics. */
  body {
    @apply font-open;
  }
  /*- Body basics. */

  /*+ Headers basics. */
  h1 {
    @apply text-xl md:text-3xl font-bold font-open;
  }
  h2 {
    @apply text-lg md:text-xl font-bold font-open;
  }
  /*- Headers basics. */
  .or-circle {
    margin-left: -12.5px;
    margin-right: -12.5px;
    height: 25px;
    width: 25px;
    overflow: hidden;
    font-size: 11px;
  }

  /*+ Override Toasted positioning. */
  @media screen and (min-width: 768px) {
    .toasted-custom-position {
      top: 0 !important;
      right: 10px !important;
    }
  }
  /*- Override Toasted positioning. */
}

@layer components {
  /*+ Main Container. */
  #container {
    @apply overflow-x-hidden;
  }
  /*- Main Container. */

  /*+ Main Navigation. */
  .main-navigation {
    @apply w-full fixed top-0 z-50 flex items-stretch bg-white shadow-md text-base;
  }
  .main-navigation a {
    @apply cursor-pointer;
  }
  .main-navigation .main-menu-wrapper {
    @apply md:flex md:self-stretch;
  }
  .main-navigation .toggle-hamburger-wrapper {
    @apply flex md:hidden fixed right-0 top-0 h-14 items-center w-14;
  }
  .main-navigation .toggle-hamburger {
    @apply text-staapurple-light focus:outline-none self-stretch flex justify-center items-center w-full;
  }
  .main-navigation .hamburger-content {
    @apply w-screen md:w-auto absolute md:relative top-14 md:top-0 z-50 md:flex md:justify-between md:flex-grow md:self-stretch bg-white overflow-y-auto md:overflow-y-visible;
  }
  @media screen and (max-width: 767px) {
    .main-navigation .hamburger-content {
      height: calc(100vh - theme(spacing.14));
    }
  }
  .main-navigation .header-left {
    @apply flex items-stretch w-full;
  }
  .main-navigation .header-right {
    @apply flex items-stretch justify-between bg-staapurple-light;
  }
  .main-navigation .header-logo-wrapper {
    @apply self-stretch block;
  }
  .main-navigation .header-logo-wrapper a {
    @apply py-3.5 px-4 font-bold flex flex-wrap w-full h-full items-center hover:bg-staapurple-light text-gray-900 hover:text-white;
  }
  .main-navigation .header-logo-wrapper a.active {
    @apply text-white bg-staapurple-light;
  }
  .main-navigation .main-menu {
    @apply md:flex md:items-stretch md:w-auto;
  }
  .main-navigation .main-menu .menu-item {
    @apply block md:flex md:items-stretch md:flex-grow;
  }
  .main-navigation .menu-item .menu-link {
    @apply px-4 py-3.5 flex flex-wrap w-full h-full items-center leading-none transition duration-100 ease-in-out hover:bg-staapurple-light text-gray-900 hover:text-white self-stretch;
  }
  .main-navigation .menu-item .menu-link.active {
    @apply bg-staapurple-light text-white;
  }
  .main-navigation .dropdown-menu .menu-link.active {
    @apply text-black font-bold;
  }
  /*- Main Navigation. */

  /*+ Global Buttons. */
  .staa-button-green {
    @apply rounded text-center px-3 py-2.5 text-white hover:text-white bg-staagreen-medium hover:bg-staagreen-dark;
  }
  /*- Global Buttons. */

  /*+ Tables custom styling. */
  a.table-link {
    @apply text-blue-500 hover:text-blue-600;
  }
  .staa-table {
    @apply bg-white border-collapse border border-gray-300 rounded;
  }
  .staa-table th,
  .staa-table td {
    @apply px-3 py-4 text-base border border-t-0 border-l-0 last:border-r-0 bg-gray-50 hover:bg-gray-100;
  }
  .staa-table tbody tr:nth-child(even) td {
    @apply bg-white hover:bg-gray-50;
  }
  .staa-table th {
    @apply transition leading-none font-bold border-gray-300 relative pr-6 text-gray-900 bg-gray-100 hover:bg-gray-200;
  }
  .staa-table th span.sorting {
    @apply cursor-pointer absolute top-0 right-0 flex flex-col h-full justify-items-stretch;
  }
  .staa-table th span.sorting span {
    @apply px-1 h-full flex flex-col justify-center hover:text-blue-600 transition;
  }
  .staa-table th.no-sorting {
    @apply pr-3;
  }
  .staa-table td {
    @apply border-gray-200;
  }
  .staa-table-filters {
    @apply w-full flex flex-wrap my-1 md:w-2/6 lg:w-1/4 xl:w-1/5;
  }
  .alert-no-results {
    @apply p-3 border border-gray-300;
  }
  /*- Tables custom styling. */

  /*+ STAA Table Pagination. */
  .staa-table-pagination {
    @apply flex text-center rounded-md overflow-hidden border border-gray-300 flex-wrap;
  }
  .staa-table-pagination li {
    @apply text-sm md:text-base border-r border-gray-300 p-2 md:p-3 hover:text-black cursor-pointer inline-block flex-grow;
  }
  .staa-table-pagination li.active {
    @apply text-white bg-blue-500;
  }
  .staa-table-pagination li.disabled {
    @apply opacity-50 pointer-events-none;
  }
  .staa-table-pagination li.separator {
    @apply text-xs md:text-base pointer-events-none border-r border-gray-300 flex-grow-0 md:flex-grow;
  }
  /*- STAA Table Pagination. */

  /*+ VueTailwind customizations. */
  .selectButtonIcon {
    @apply fill-current flex-shrink-0 ml-1 h-4 w-4;
  }
  /*- VueTailwind customizations. */

  /*+ Triangle bottom. */
  .triangle-bottom {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    @apply box-border w-0 h-0 -mt-px;
  }
  /*- Triangle bottom. */
  .btn-primary {
    @apply bg-staaVacancy py-2.5 px-6 rounded-lg border-staaVacancy border hover:bg-staaVacancyHover
            focus-visible:border-black focus-visible:border-4 active:bg-staaVacancyActive;
  }

  .text-sm-color-staa-primary {
    @apply text-sm font-medium leading-tight text-staaVacancy;
  }

  .btn-primary-text {
    @apply text-base font-medium text-slate-50;
  }

  .btn-secondary {
    @apply inline-flex space-x-2 items-center justify-center px-6 py-2.5 shadow-sm border-2 rounded-lg border-staaVacancy
            hover:border-staaVacancyHover focus-visible:border-4 focus-visible:border-black active:border-staaVacancyActive;
  }

  .btn-secondary-text {
    @apply text-base font-medium leading-normal text-center text-staaVacancy active:text-staaVacancyActive;
  }

  .form-input-container-default {
    @apply flex items-center border-b border-slate-300 pb-1 hover:border-slate-500 text-slate-400 focus-within:text-staaVacancy;
  }

  .form-input-container-border-hidden {
    @apply flex items-center border-0 text-slate-800;
  }

  .form-input-container-error {
    @apply flex items-center border-b border-red-600 pb-1 hover:border-red-700 focus-within:border-slate-700 text-slate-400;
  }

  .form-input-default {
    @apply bg-transparent border-none w-full text-slate-700 mr-3 px-2 leading-tight
            focus:outline-0 focus:text-slate-700 focus:placeholder:text-slate-900;
  }

  .form-input-error {
    @apply bg-transparent border-none w-full mr-3 px-2 leading-tight
            focus:outline-0 focus:text-slate-700;
  }

  .form-header {
    @apply text-2xl font-medium leading-loose text-slate-900 flex justify-center;
  }

  .form-header-details {
    @apply text-sm leading-tight text-slate-400 flex justify-center;
  }

  .link-icon {
    @apply ml-2 h-4 w-4;
  }

  .cta-link {
    @apply text-sm font-medium leading-tight text-center text-blue-600 flex;
  }

  input:checked + div {
    @apply border-staaVacancy;
  }

  input:checked + div svg {
    @apply block;
  }

  .input-label-default {
    @apply text-sm leading-tight text-slate-500 relative invisible group-focus-within:visible;
  }

  .input-label-error {
    @apply text-sm leading-tight text-red-700 relative invisible group-focus-within:visible;
  }

  .validation-error-text {
    @apply text-xs text-red-700 font-normal;
  }

  .required:after {
    content: " *";
    color: #dc2626;
  }

  .not-req.required:after {
    content: "";
  }

  #page-content p {
    @apply mb-4;
  }

  .user-content {
    @apply justify-center max-w-[348px] mx-auto p-4;
  }

  .secondDiv {
    @apply max-h-[77vh] overflow-y-scroll scrollbar;
  }

  .entryDiv {
    @apply flex pt-2 pb-2 space-x-2 w-full;
  }

  .lang-option {
    @apply flex items-center pt-2;
  }

  .dropdown-menu {
    @apply text-sm text-gray-700 max-h-80 scrollbar overflow-y-auto bg-white divide-y rounded-lg;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 7px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #f1f5f9;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #e50d8a;
    border-radius: 100vh;
    border: #f6f7ed;
  }

  .hide-dropdown-arrow {
    -webkit-appearance: none;
  }
}

@layer base {
  body {
    @apply leading-tight;
  }
}
